import React from 'react';

function InstructionsScreen({ history }) {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
      <button onClick={() => window.history.back()} style={styles.backButton}>← Back</button>

        <h2 style={styles.headerTitle}>INSTRUCTION</h2>
      </div>
      <div style={styles.instructionsContainer}>
        <p style={styles.instructionsText}>
          Instructions on how to work with the predictor application: <br /><br />
          1. When the app predicts coefficients for you, it is important to bet exactly and not just watch and wait. Neglecting this rule may cause the app to become disconnected. <br /><br />
          2. Please bet exclusively when the app predicts the Coefficient. This is extremely important. <br /><br />
          3. Please cash out your bet strictly when the app tells you to, no earlier and no later. <br /><br />
          4. Never reboot the app without agreeing to this action. <br /><br />
          5. Never perform diagnostics without agreeing to this action. <br /><br />
          Observance of these rules is necessary for successful work with the predictor application. ✓
        </p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex', // Already set for flex, good for web.
    flexDirection: 'column', // Stack children vertically.
    minHeight: '100vh', // Full viewport height.
    backgroundColor: '#EAEFFD',
    fontFamily: 'Arial, sans-serif', // Use a fallback font.
    margin: 0, // Reset default margin
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start', // Aligns items to the start of the header.
    padding: '20px',
    borderBottom: '1px solid #D1D5DB',
    backgroundColor: '#FFF',
  },
  backButton: {
    marginRight: 'auto', // Ensures that the header title is pushed to the center.
    fontSize: '18px',
    color: '#000',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    zIndex: 2, // Зменшуємо z-index для заголовка, щоб кнопка "Back" могла бути клікабельною

  },
  headerTitle: {
    textAlign: 'center', // Center the title text
    width: '100%', // Make the title take the full width available
    position: 'absolute', // Position absolutely to center in the header
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '22px',
    fontWeight: '600',
    color: '#1F2937',
    zIndex: 1, // Зменшуємо z-index для заголовка, щоб кнопка "Back" могла бути клікабельною
    margin: '0', // Remove margin to prevent offset in absolute positioning
  },
  instructionsContainer: {
    padding: '20px',
    overflowY: 'auto', // Allows scrolling if content is too long.
    maxWidth: '800px', // Max width for readability.
    margin: '0 auto', // Centers the instructions in the page.
  },
  instructionsText: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#4B5563',
    textAlign: 'left', // Ensures text is aligned left.
    whiteSpace: 'pre-line', // Respects the line breaks in your string.
  },
};

export default InstructionsScreen;

