import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from './Pages/HomeScreen';
import AviatorScreen from './Pages/Aviator';
import JetxScreen from './Pages/Jetx';
import CreicketScreen from './Pages/Creicket';
import DiagnosticsScreen from './Pages/Diagnostic';
import AppRebootScreen from './Pages/Reboot';
import InstructionsScreen from './Pages/Instruction';
import VisitorProvider from './Components/VisitorProvider';

function App() {
  return (
    <VisitorProvider>
      <Router>
        <Routes> {/* Замість `Switch` використовується `Routes` */}
          <Route path="/" element={<HomeScreen />} />
          <Route path="/aviator" element={<AviatorScreen />} />
          <Route path="/cricket" element={<CreicketScreen />} />
          <Route path="/jetx" element={<JetxScreen />} />
          <Route path="/instructions" element={<InstructionsScreen />} />
          <Route path="/diagnostics" element={<DiagnosticsScreen />} />
          <Route path="/reboot" element={<AppRebootScreen />} />
          {/* Додайте інші маршрути тут */}
        </Routes>
      </Router>
    </VisitorProvider>
  );
}

export default App;
