import React from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../Components/Header';

const game1Image = 'aviator.jpg';
const game2Image = 'jetx.jpg';
const game3Image = 'cricket.jpg';

const games = [
  { id: '1', title: 'Aviator', img: game1Image, screen: '/aviator' },
  { id: '2', title: 'Jet X', img: game2Image, screen: '/jetx' },
  { id: '3', title: 'Cricket X', img: game3Image, screen: '/cricket' },
];

function HomeScreen() {
  const navigate = useNavigate();


  return (
    <div style={styles.container}>
      <Header title="Hack casino" />
      <div style={styles.scrollView}>
        {games.map((game) => (
          <div
            key={game.id}
            style={styles.gameBlock}
            onClick={() => navigate(game.screen)}
          >
            <img src={game.img} alt={game.title} style={styles.gameImage} />
            <p style={styles.gameTitle}>{game.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  scrollView: {
    overflowY: 'auto', // Дозволяє прокрутку
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
  },
  gameBlock: {
    width: '300px',
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
    backgroundColor: '#ddd',
    borderRadius: '10px',
    cursor: 'pointer', // Вказує, що елемент клікабельний
  },
  gameImage: {
    width: '250px',
    height: '200px',
  },
  gameTitle: {
    marginTop: '10px',
    fontSize: '24px',
    color: '#000',
    textShadow: '1px 1px 10px rgba(255, 255, 255, 0.7)',
  },
};
export default HomeScreen;
