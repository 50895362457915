// Header.js
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Header = ({ title }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const getNavLinkStyle = (isActive) => ({
    ...styles.navLink,
    ...(isActive ? styles.navLinkActive : null),
  });

  return (
    <div style={styles.header}>
      <button onClick={toggleDrawer} style={styles.menuButton}>
        ☰
      </button>
      <span style={styles.title}>{title || 'Default Title'}</span>
      {isDrawerOpen && (
        <div style={styles.menu}>
          <button onClick={toggleDrawer} style={styles.closeButton}>✕</button>
          <NavLink to="/" exact style={({ isActive }) => getNavLinkStyle(isActive)} onClick={toggleDrawer}>
            Home
          </NavLink>
          <NavLink to="/instructions" style={({ isActive }) => getNavLinkStyle(isActive)} onClick={toggleDrawer}>
          Instructions
          </NavLink>
          <NavLink to="/diagnostics" style={({ isActive }) => getNavLinkStyle(isActive)} onClick={toggleDrawer}>
            Diagnostics
          </NavLink>      
          <NavLink to="/reboot" style={({ isActive }) => getNavLinkStyle(isActive)} onClick={toggleDrawer}>
            Reboot
          </NavLink>
          {/* Інші посилання */}
        </div>
      )}
    </div>
  );
};

const styles = {
  header: {
    height: '60px',
    width: '100%',
    backgroundColor: 'darkslateblue',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '10px',
    marginBottom: '20px',
    position: 'relative', // Додаємо для позиціонування меню
  },
  title: {
    color: '#fff',
    fontSize: '24px',
    marginLeft: '20px',
  },
  menuButton: {
    fontSize: '24px',
    color: '#fff',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  menu: {
    position: 'absolute',
    top: '60px', // Висота хедера
    left: 0,
    width: '250px',
    background: '#282c34',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
    boxShadow: '3px 0px 10px rgba(0, 0, 0, 0.5)',
  },
  closeButton: {
    alignSelf: 'flex-end',
    background: 'none',
    border: 'none',
    color: 'white',
    padding: '10px',
    cursor: 'pointer',
    fontSize: '24px',
  },
  navLink: {
    textDecoration: 'none',
    color: 'white',
    fontSize: '18px',
    padding: '10px 0',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    transition: 'background-color 0.3s',
  },
  navLinkActive: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },

};

export default Header;
