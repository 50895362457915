import React, { useState } from 'react';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import animationData from '../Animations/reboot.json'; // Переконайтеся, що шлях до файлу анімації вірний

function AppRebootScreen() {
  const [isRebooting, setIsRebooting] = useState(false);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleBack = () => {
    navigate('/'); // Navigate to the home screen
  };

  const handleReboot = () => {
    setIsRebooting(true);
    // Чекати 10 секунд перед симуляцією перезапуску додатку
    setTimeout(() => {
      setIsRebooting(false);
      navigate('/'); // Navigate to the home screen
    }, 10000);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <button onClick={handleBack} style={styles.backButton}> ← Back </button>
        <h2 style={styles.headerTitle}>APP REBOOT</h2>
      </div>
      <div style={styles.content}>
        <h3 style={styles.attentionText}>ATTENTION</h3>
        <div style={styles.attentionContainer}>
          <p style={styles.instructionsText}>
            Reboot the application only after agreeing to this action.
          </p>
        </div>
        {isRebooting && <Lottie options={defaultOptions} height={200} width={200} />}
        <button onClick={handleReboot} style={styles.rebootButton}>
          App Reboot
        </button>
      </div>
    </div>
  );
}

// Стилі можна адаптувати з використанням JavaScript об'єктів, як у React Native, але для вебу:
const styles = {
  container: {
    display: 'flex', // Using flex to structure the container
    flexDirection: 'column', // Stack children vertically
    minHeight: '100vh', // Ensure it covers the full viewport height
    backgroundColor: '#EAEFFD',
    padding: '20px',
    boxSizing: 'border-box', // Include padding in the width and height
  },
  backButton: {
    marginRight: 'auto', // Pushes everything else to the right
    fontSize: '18px',
    color: '#000',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    zIndex: 2, // Збільшуємо z-index для кнопки "Back", щоб вона була клікабельною
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderBottom: '1px solid #D1D5DB',
    backgroundColor: '#FFF',
    padding: '20px',
    position: 'relative', // Додано для забезпечення правильного контексту налаштування
    zIndex: 10, // Підвищуємо z-index для header, щоб він був над іншими елементами
  },
  
  headerTitle: {
    textAlign: 'center', // Center the title text
    width: '100%', // Make the title take the full width available
    position: 'absolute', // Position absolutely to center in the header
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '22px',
    fontWeight: '600',
    color: '#1F2937',
    zIndex: 1, // Зменшуємо z-index для заголовка, щоб кнопка "Back" могла бути клікабельною
    margin: '0', // Remove margin to prevent offset in absolute positioning
  },
  content: {
    flex: 1, // Take up all available space after the header
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Center content vertically
    alignItems: 'center', // Center content horizontally
    padding: '20px',
  },
  attentionText: {
    fontSize: '22px',
    fontWeight: 'bold',
    color: 'red',
    marginBottom: '10px',
  },
  instructionsText: {
    fontSize: '16px',
    color: '#9C4221',
    textAlign: 'center',
    marginBottom: '20px',
  },
  rebootButton: {
    backgroundColor: '#4F46E5',
    color: '#FFF',
    padding: '15px',
    borderRadius: '10px',
    border: 'none',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  attentionContainer: {
    marginBottom: '30px',
    padding: '20px',
    backgroundColor: '#FEF3C7',
    borderRadius: '10px',
    borderWidth: '1px',
    borderColor: '#FED7AA',
  },
};


export default AppRebootScreen;
