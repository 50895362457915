import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import VisitorContext from '../Components/visitorContext'; // Переконайтеся, що шлях до контексту правильний
import  API_BASE_URL  from '../config'; // Імпортуйте або визначте API_BASE_URL
import Lottie from 'react-lottie';
import animationData from '../Animations/AnimationAviator.json'; // Шлях до вашого Lottie файлу

const AviatorScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [multiplier, setMultiplier] = useState(null);
  const { visitor } = useContext(VisitorContext);
  const [accessCode, setAccessCode] = useState('');
  const [requestStatus, setRequestStatus] = useState('');
  const [isAccessGranted, setIsAccessGranted] = useState(false);
  const [predictionCount, setPredictionCount] = useState(0); // Додатковий стан для відслідковування кількості спроб передбачення

  const handleGetAccess = async () => {
    if (visitor && visitor.visitorId) {
        try {
            const response = await axios.post(`${API_BASE_URL}/api/visitors`, {
                visitorId: visitor.visitorId,
                sourceVisitor: 'webhack',
                contentType: 'aviator'
            });
            if (response.status === 201) {
                const { accessCode } = response.data;
                localStorage.setItem('aviator_accessCode', accessCode); // Зберігаємо accessCode з префіксом
                setAccessCode(accessCode);
                setRequestStatus('pending');
            }
        } catch (error) {
            console.error('Error requesting access', error);
            setRequestStatus('error');
        }
    }
};

useEffect(() => {
  // Перевірка, якщо у нас вже є accessCode
  const savedAccessCode = localStorage.getItem('aviator_accessCode');
  if (visitor && visitor.visitorId && savedAccessCode) {
    setAccessCode(savedAccessCode); // Встановлюємо збережений accessCode у стан
    checkAccessStatus(visitor.visitorId, savedAccessCode); // Перевіряємо статус доступу
  }
}, [visitor]);

const checkAccessStatus = async (visitorId, accessCode) => {
  try {
      const response = await axios.get(`${API_BASE_URL}/api/visitors/access-status`, {
          params: { visitorId, accessCode }
      });
      if (response.data.statusAccess === 'approved') {
          setIsAccessGranted(true);
      } else {
          setRequestStatus(response.data.statusAccess); // 'pending' або 'denied'
      }
  } catch (error) {
      console.error('Error checking access status', error);
  }
};


  const handleLogin = async () => {
    // Тут буде логіка перевірки статусу доступу
    try {
      const response = await axios.get(`${API_BASE_URL}/api/visitors/access-status`, {
        params: {
          visitorId: visitor.visitorId,
          accessCode: accessCode
        }
      });

      if (response.data.statusAccess === 'approved') {
        setIsAccessGranted(true);
        alert('Access Granted. Welcome to Aviator Predictor!');
      } else if (response.data.statusAccess === 'pending') {
        alert('Please write to your manager to grant access.');
      } else {
        alert('Access Denied.');
      }
    } catch (error) {
      console.error('Error checking access status', error);
    }
  };

  // Оновлена функція generateMultiplier
  const generateMultiplier = () => {
    setIsLoading(true);
    setTimeout(() => {
      let randomMultiplier;
      switch (predictionCount) {
        case 0:
          randomMultiplier = 3.34;
          break;
        case 1:
          randomMultiplier = 25.65;
          break;
        case 2:
          randomMultiplier = 28.52;
          break;
        case 3:
          randomMultiplier = 7.33;
          break;
        case 4:
          randomMultiplier = 2.56;
          break;
        case 5:
          randomMultiplier = 3.76;
          break;
        case 6:
          randomMultiplier = 11.46;
          break;
        case 7:
          randomMultiplier = 13.07;
          break;
        case 8:
          randomMultiplier = 14.07;
          break;
        default:
          randomMultiplier = getWeightedRandomMultiplier();
          break;
      }

      setMultiplier(randomMultiplier);
      setPredictionCount(predictionCount + 1); // Збільшуємо лічильник спроб передбачення
      setIsLoading(false);
    }, 6000);
  };

  const getWeightedRandomMultiplier = () => {
    const rand = Math.random();
    return rand < 0.5
      ? (Math.random() * 4 + 1).toFixed(2)
      : rand < 0.8
      ? (Math.random() * 5 + 5).toFixed(2)
      : (Math.random() * 20 + 10).toFixed(2);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <button onClick={() => window.history.back()} style={styles.backButton}>← Back</button>
      </div>
  
      <div style={styles.centerContent}>
        <img src="/aviator.jpg" alt="Aviator Predictor" style={styles.image} />
        <h2 style={styles.title}>Aviator Predictor</h2>
      </div>
  
      <div style={styles.loginContainer}>
        {/* Show the "Get Access" button only if accessCode is not set and access is not granted */}
        {!accessCode && !isAccessGranted && (
          <button onClick={handleGetAccess} style={styles.accessButton}>Get Access</button>
        )}
  
        {/* Show access code and "Log In" button only if accessCode is set and access is not granted */}
        {accessCode && !isAccessGranted && (
          <>
            <p>Your access code: {accessCode}</p>
            <button onClick={handleLogin} style={styles.loginButton}>Log In</button>
          </>
        )}
  
        {/* Show the prediction interface only if access is granted */}
        {isAccessGranted && (
          <div style={styles.predictionContainer}>
            {isLoading ? (
              <Lottie options={defaultOptions} height={250} width={250} />
            ) : (
              <>
                <p style={styles.multiplierText}>{multiplier}x</p>
                <button onClick={generateMultiplier} style={styles.predictionButton}>Prediction</button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#EAEFFD',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    maxWidth: '100%', // Встановлює максимальну ширину зображення відповідно до ширини контейнера
    maxHeight: '100px', // Максимальна висота зображення, щоб воно не було занадто великим на великих екранах
    height: 'auto', // Висота автоматично адаптується, зберігаючи пропорції зображення
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start', // Aligns items to the start of the header.
    padding: '20px',
    borderBottom: '1px solid #D1D5DB',
    backgroundColor: '#FFF',
  },
  backButton: {
    alignSelf: 'flex-start', // Aligns the button to the start of the flex container
    marginRight: '20px',
    fontSize: '18px',
    color: '#000',
    textDecoration: 'none',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  centerContent: {
    textAlign: 'center',
    marginTop: '50px',
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  passwordInput: {
    padding: '15px',
    marginBottom: '20px',
    borderRadius: '10px',
    border: '2px solid #CBD5E1',
    width: '100%', // Для повної ширини
    maxWidth: '400px', // Максимальна ширина для великих екранів
  },
  loginButton: {
    padding: '15px',
    borderRadius: '10px',
    backgroundColor: '#4F46E5',
    color: '#FFF',
    border: 'none', // Видалити стандартний border
    cursor: 'pointer',
  },
  predictionContainer: {
    width: '100%',
    textAlign: 'center',
  },
  multiplierText: {
    fontSize: '24px',
    color: 'darkslateblue',
    margin: '20px 0',
  },
  predictionButton: {
    padding: '10px 30px',
    borderRadius: '10px',
    backgroundColor: 'darkslateblue',
    color: '#FFF',
    border: 'none',
    cursor: 'pointer',
  },
  accessButton: {
    padding: '10px 30px',
    borderRadius: '10px',
    backgroundColor: 'darkslateblue',
    color: '#FFF',
    border: 'none',
    cursor: 'pointer',
    marginTop: '20px', // Додайте відступ зверху
  },
  lottieAnimation: {
    height: '250px',
    width: '250px',
    alignSelf: 'center', // Центрувати анімацію
  }
};

export default AviatorScreen;
