import React, { useState } from 'react';
import Lottie from 'react-lottie';
import animationData from '../Animations/loading.json'; // Впевніться, що шлях до файлу анімації вірний

const DiagnosticsScreen = () => {
  const [isDiagnosing, setIsDiagnosing] = useState(false);
  const [diagnosisResult, setDiagnosisResult] = useState(null);

  const handleDiagnostics = () => {
    setIsDiagnosing(true);
    setDiagnosisResult(null);

    setTimeout(() => {
      setDiagnosisResult('The diagnostic check revealed no problems');
      setIsDiagnosing(false);
    }, 10000);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <button onClick={() => window.history.back()} style={styles.backButton}>← Back</button>
        <h2 style={styles.headerTitle}>APP DIAGNOSTICS</h2>
      </div>
      <div style={styles.content}>
        <h3 style={styles.attentionText}>ATTENTION</h3>
        <div style={styles.attentionContainer}>
          <p style={styles.instructionsText}>
            Perform diagnostics of the app only after this action has been agreed upon.
          </p>
        </div>
        <button onClick={handleDiagnostics} style={styles.diagnosticsButton}>App Diagnostics</button>
        {isDiagnosing && <Lottie options={defaultOptions} height={200} width={200} />}
        {!isDiagnosing && diagnosisResult && <p style={styles.diagnosisResultText}>{diagnosisResult}</p>}
      </div>
    </div>
  );
};

const styles = {
  container: {
    flex: 1,
    backgroundColor: '#EAEFFD',
    padding: '20px',
    height: '100vh', // Full viewport height
    boxSizing: 'border-box', // Ensures padding is included in the height
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start', // Aligns items to the start of the header.
    padding: '20px',
    borderBottom: '1px solid #D1D5DB',
    backgroundColor: '#FFF',
  },
  headerTitle: {
    textAlign: 'center', // Center the title text
    width: '100%', // Make the title take the full width available
    position: 'absolute', // Position absolutely to center in the header
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '16px',
    fontWeight: '600',
    color: '#1F2937',
    zIndex: 1, // Зменшуємо z-index для заголовка, щоб кнопка "Back" могла бути клікабельною
    margin: '0', // Remove margin to prevent offset in absolute positioning
  },
  attentionContainer: {
    marginBottom: '30px',
    padding: '20px',
    backgroundColor: '#FEF3C7',
    borderRadius: '10px',
    borderWidth: '1px',
    borderColor: '#FED7AA',
  },
  instructionsText: {
    fontSize: '16px',
    color: '#9C4221',
    textAlign: 'center',
  },
  
  backButton: {
    alignSelf: 'flex-start', // Aligns the button to the start of the flex container
    marginRight: '20px',
    fontSize: '18px',
    color: '#000',
    textDecoration: 'none',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    zIndex: 2, // Зменшуємо z-index для заголовка, щоб кнопка "Back" могла бути клікабельною
  },
  
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  attentionText: {
    fontSize: '22px',
    fontWeight: 'bold',
    color: 'red',
    marginBottom: '10px',
  },
  diagnosticsButton: {
    backgroundColor: '#4F46E5',
    color: '#FFF',
    padding: '15px',
    borderRadius: '10px',
    marginBottom: '20px',
    border: 'none',
    cursor: 'pointer',
  },
  diagnosisResultText: {
    fontSize: '18px',
    color: 'green',
    textAlign: 'center',
    marginTop: '20px',
  },
};

export default DiagnosticsScreen;
